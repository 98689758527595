/* global _vwo_campaignData */
/* eslint-disable camelcase */

export default function initHeapVwo() {
	var _vis_opt_queue = window._vis_opt_queue || [];
	_vis_opt_queue.push(function() {
		try {
			if (_vwo_campaignData && Object.keys(_vwo_campaignData).length !== 0) {
				window.vwoCampaignData = {};
				var vwoVariation,
					vwoTestID = Object.keys(_vwo_campaignData)[0];
				if (vwoTestID) {
					vwoVariation = _vwo_campaignData[vwoTestID].n;
					if (vwoVariation) {
						window.vwoCampaignData['VWO Test ID'] = vwoTestID;
						window.vwoCampaignData['VWO Variation Name'] = vwoVariation;
					}
				}
				// Use the vwoCampaignData object created above to fetch the data
				// And map user and event properties for all following actions
				window.Modalytics.heap('addUserProperties', window.vwoCampaignData);
				window.Modalytics.heap('addEventProperties', window.vwoCampaignData);
			}
		} catch (error) {
			console.log('Something went wrong!', error);
		}
	});
}
